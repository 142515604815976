import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { AmbassadorEventDetailPage } from '~/components/Account/AmbassadorPortal/AmbassadorEventDetailPage';
import { AmbassadorEvent, Article } from '../types/types';

const AmbassadorEventTemplate: React.FC<AmbassadorEventTemplateProps> = ({
  data,
}) => {
  const { event, otherEventsNodes } = data;

  const otherEvents = otherEventsNodes.edges.map(({ node }) => node);

  return (
    <Layout>
      <Metadata title={event.title} />
      <AmbassadorEventDetailPage event={event} otherEvents={otherEvents} />
    </Layout>
  );
};

export const query = graphql`
  query AmbassadorEventPageQuery($slug: String, $locale: String) {
    event: contentfulAmbassadorEvent(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...AmbassadorEventPageFragment
    }
    otherEventsNodes: allContentfulAmbassadorEvent(
      filter: { slug: { ne: $slug } }
    ) {
      edges {
        node {
          ...AmbassadorEventCardFragment
        }
      }
    }
  }
`;

interface AmbassadorEventTemplateProps {
  data: {
    event: AmbassadorEvent;
    otherEventsNodes: {
      edges: {
        node: AmbassadorEvent;
      }[];
    };
  };
}

export default AmbassadorEventTemplate;
