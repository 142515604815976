import React, { useMemo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Section } from '~/styles/Section.styled';
import { Wrapper } from '~/styles/Wrapper.styled';
import { Cta } from '~/components/Generic/Cta/Cta';
import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';
import {
  PageSection,
  ComponentMapExtraData,
} from '~/components/PageSection/PageSection';
import { AmbassadorEvent } from '~/types/types';
import {
  AmbassadorResourceCard,
  AmbassadorResourceCardItem,
} from './AmbassadorResourceCard';

import { StyledAmbassadorEventDetailPage } from './AmbassadorEventDetailPage.style';

const eventSectionsDefaultData: ComponentMapExtraData = {};

export const AmbassadorEventDetailPage: React.FC<
  AmbassadorEventDetailPageProps
> = ({ event, otherEvents }) => {
  const {
    title,
    topText,
    heroImage,
    date,
    price,
    sections,
    primaryCtaLabel,
    primaryCtaUrl,
    secondaryCtaLabel,
    secondaryCtaUrl,
  } = event;

  const formattedDate = useMemo(
    () =>
      date &&
      new Date(date).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        timeZoneName: 'short',
      }),
    [date]
  );

  return (
    <StyledAmbassadorEventDetailPage>
      <Section>
        <Wrapper width="normal">
          <div className="event-page__header">
            <div className="event-page__title">
              <ShowOnScroll>
                <h1>{title}</h1>
              </ShowOnScroll>
              <ShowOnScroll delay={100}>
                <p className="event-page__top-text">{topText}</p>
              </ShowOnScroll>
            </div>
            <div className="event-page__details">
              <div className="event-page__infos">
                {formattedDate && (
                  <ShowOnScroll delay={100} className="event-page__date">
                    <div className="event-page__infos-title">Date & Time</div>
                    {formattedDate}
                  </ShowOnScroll>
                )}
                {price && (
                  <ShowOnScroll delay={100} className="event-page__price">
                    <div className="event-page__infos-title">Price</div>
                    {`$${(+price).toFixed(2)}`}
                  </ShowOnScroll>
                )}
              </div>
              <ShowOnScroll delay={150} className="event-page__ctas">
                {primaryCtaLabel && primaryCtaUrl && (
                  <Cta
                    href={primaryCtaUrl}
                    className="cta"
                    variant="primary"
                    size="large"
                  >
                    {primaryCtaLabel}
                  </Cta>
                )}
                {secondaryCtaLabel && secondaryCtaUrl && (
                  <Cta href={secondaryCtaUrl} className="cta" size="large">
                    {secondaryCtaLabel}
                  </Cta>
                )}
              </ShowOnScroll>
            </div>
          </div>
        </Wrapper>
      </Section>

      {heroImage && (
        <Section>
          <Wrapper rounded={false} gutter={false} width="full">
            <ShowOnScroll delay={200}>
              <GatsbyImage
                image={heroImage.gatsbyImageData}
                alt={heroImage.title}
                objectFit="cover"
              />
            </ShowOnScroll>
          </Wrapper>
        </Section>
      )}

      {sections &&
        sections.map((section, idx) => {
          if (!section?.sectionType) {
            return null;
          }

          const componentData =
            eventSectionsDefaultData[section.sectionType.slug] || {};

          return (
            <PageSection
              key={`${section.slug}_${idx}`}
              section={section}
              {...componentData}
            />
          );
        })}

      {otherEvents.length && (
        <Section className="events-list" gutter>
          <Wrapper width="wide" bg="gray">
            <div className="events-list__container">
              <h2>What else is happening now</h2>
              <div className="items-list--grid items-list--grid__events">
                {otherEvents.map((e, i) => {
                  const mappedEvent = {
                    id: e.id,
                    slug: e.slug,
                    title: e.title,
                    shortDescription: e.shortDescription,
                    date: e.date,
                  };
                  return (
                    <ShowOnScroll key={`event-${e.slug}`} delay={50 * i}>
                      <AmbassadorResourceCard
                        item={mappedEvent as AmbassadorResourceCardItem}
                      />
                    </ShowOnScroll>
                  );
                })}
              </div>
            </div>
          </Wrapper>
        </Section>
      )}
    </StyledAmbassadorEventDetailPage>
  );
};

interface AmbassadorEventDetailPageProps {
  event: AmbassadorEvent;
  otherEvents: AmbassadorEvent[];
}
