import React, { useMemo } from 'react';
import { StyledAmbassadorResourceCard } from './AmbassadorResourceCard.style';

export const AmbassadorResourceCard: React.FC<{
  item: AmbassadorResourceCardItem;
}> = ({ item }) => {
  const formattedDateTime = useMemo(
    () =>
      new Date(item.date).toLocaleString(undefined, {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      }),
    [item.date]
  );

  return (
    <StyledAmbassadorResourceCard className="">
      <h3>{item.title}</h3>
      {item.date && <h4>{formattedDateTime}</h4>}
      {item.shortDescription && <p>{item.shortDescription}</p>}
      <div className="Actions">
        <a className="Action" href={`/ambassador-events/${item.slug}`}>
          Get the details
        </a>
      </div>
    </StyledAmbassadorResourceCard>
  );
};

export interface AmbassadorResourceCardItem {
  id: string;
  slug: string;
  title: string;
  shortDescription?: string;
  date?: string;
}
