import styled from 'styled-components';
export const StyledAmbassadorResourceCard = styled.div`
  max-width: 330px;
  width: 100%;
  padding-bottom: 6rem;
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    color: var(--black);
    margin: 0 0 1rem 0;
  }
  h4 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin: 0 0 0.5rem 0;
  }
  p {
    margin: 0;
  }
  .Actions {
    margin-top: 1rem;
  }
  .Action {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--black);
    text-decoration: none;
    position: relative;
    display: inline-block;
    margin-right: 25px;
    padding-bottom: 3px;
    &:after,
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: currentColor;
      transition: width 0.3s ease-in-out;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &:before {
      width: 0;
      transition-delay: 0.3s;
      left: 0;
      right: unset;
    }
    &:hover {
      &:before {
        width: 100%;
      }
      &:after {
        width: 0;
      }
    }
  }
`;
