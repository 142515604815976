import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';
import { Cta } from '~/components/Generic/Cta/Cta';

export const StyledAmbassadorEventDetailPage = styled.div`
  margin-top: ${pxtorem(40)};
  h1 {
    font-family: var(--secondaryFont);
    font-style: italic;
    font-size: ${pxtorem(34)};
    line-height: 1.1372549;
    margin-bottom: var(--gutter);

    @media (min-width: 768px) {
      font-size: ${pxtorem(52)};
    }
  }

  .event-page__title {
    flex: 0 1 50%;
  }

  .event-page__details {
    flex: 0 1 auto;
    font-size: ${pxtorem(24)};
  }

  .event-page__infos > div {
    margin-bottom: var(--gutter);
  }

  .event-page__top-text {
    font-size: ${pxtorem(20)};
  }

  .event-page__infos-title {
    font-size: ${pxtorem(16)};
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: ${pxtorem(4)};
  }

  .event-page__ctas .cta {
    width: 100%;
    display: block;

    &.cta {
      margin-top: calc(var(--gutter) / 2);
    }
  }

  .events-list__container {
    max-width: 1280px;
    margin: ${pxtorem(80)} auto;
  }

  @media (min-width: 768px) {
    .event-page__header {
      display: flex;
      justify-content: space-between;
    }

    .event-page__infos {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--gutter);

      > div {
        margin-bottom: 0;
      }
    }
    .event-page__date + .event-page__price {
      margin-left: calc(var(--gutter) * 2);
    }
  }
`;
